
<script setup>
    import { ref, watch, computed } from "vue";

    import MBottomSheet from "mobile.vue.components.BottomSheet.vue";
    import MContainer from "mobile.vue.components.Container.vue";
    import MContent from "mobile.vue.components.Content.vue";
    import MPill from "mobile.vue.components.Pill.vue";
    import MFieldDistinct from "mobile.vue.components.filter.fields.Distinct.vue";
    import MFieldRange from "mobile.vue.components.filter.fields.Range.vue";
    import MFieldSlider from "mobile.vue.components.filter.fields.Slider.vue";

    const props = defineProps(["filter"]);
    const emit = defineEmits([]);

    const fields = computed(() => props.filter.fields.filter(e => e.type !== "Checkbox"));
    const checkboxes = computed(() => props.filter.fields.filter(e => e.type === "Checkbox"));


    /* reset field when show changes */

    watch(() => props.filter.showFieldSheet, () => {
        if (!props.filter.showFieldSheet) {
            props.filter.selectField(null);
        }
    });


    /* animation to swap between fields and selected field */

    const anim = ref(null);

    watch(() => props.filter.field, (val, old) => {
        if (val) {
            anim.value = "route-forward";
        } else {
            anim.value = "route-back";
            if (!old.noAutoSave) {
                old.applyTempValue();
            }
        }
    });


    /* handlers */

    function selectField(field) {
        props.filter.selectField(field);
    }

    function clearField(field) {
        field.clear();
        props.filter.update();
    }
</script>

<template>
    <MBottomSheet v-model="filter.showFieldSheet" v-bind="$attrs" min-height="calc(80% - 4rem)" max-height="calc(80% - 4rem)">
        <template #title>
            <div class="d-flex justify-content-center align-items-center">
                <transition :name="anim">
                    <template v-if="filter.field">
                        <div style="position: absolute;">
                            {{ filter.field.name }}
                        </div>
                    </template>
                    <template v-else>
                        <div style="position: absolute;">
                            {{ $t("Filter") }}
                        </div>
                    </template>
                </transition>
            </div>
        </template>
        <template #action>
            <transition name="fade">
                <template v-if="!filter.field">
                    <a class="d-flex align-items-center" style="position: absolute; top: 0; left: 0; bottom: 0;" v-if="false">
                        {{ $t("Saved filters") }}
                    </a>
                </template>
                <template v-else>
                    <a class="d-flex justify-content-center align-items-center" style="height: 100%; aspect-ratio: 1; text-decoration: none;" @click="filter.selectField(null)">
                        <i class="fas fa-chevron-left" />
                    </a>
                </template>
            </transition>
        </template>
        <template #body>
            <MContainer>
                <transition :name="anim">
                    <template v-if="filter.field?.type === 'Distinct'">
                        <MFieldDistinct :filter="filter" />
                    </template>
                    <template v-else-if="filter.field?.type === 'Range'">
                        <MFieldRange :filter="filter" />
                    </template>
                    <template v-else-if="filter.field?.type === 'Slider'">
                        <MFieldSlider :filter="filter" />
                    </template>
                    <template v-else>
                        <MContainer>
                            <template v-if="checkboxes.length">
                                <div class="px-3 mt-2 mb-2 d-flex gap-2 hide-scrollbar scrollable" style="overflow-x: auto;">
                                    <template v-for="(field, index) in checkboxes">
                                        <MPill :variant="field.isChecked() ? 'contained' : 'outlined'" size="md" color="primary" @click="field.toggle()">
                                            {{ field.getText() }}
                                        </MPill>
                                    </template>
                                </div>
                            </template>
                            <MContent class="px-3 pb-3">
                                <template v-for="(field, index) in fields">
                                    <a class="d-flex align-items-center gap-2 list-item" :class="{ 'border-top': index > 0 }" style="color: black; font-size: 0.9em; padding: 0 1rem; text-decoration: none;" @click="selectField(field)">
                                        <div class="flex-shrink-0" style="padding: 0.75rem 0;">
                                            {{ field.name }}
                                        </div>
                                        <div class="flex-grow-1">
                                        </div>
                                        <template v-if="field.getFilterText()">
                                            <MPill color="primary-subtle" style="min-width: 0; font-size: 0.9em;" @close="clearField(field)">{{ field.getFilterText() }}</MPill>
                                        </template>
                                        <div class="flex-shrink-0">
                                            <i class="far fa-chevron-right text-muted" style="font-size: 0.75em;" />
                                        </div>
                                    </a>
                                </template>
                            </MContent>
                        </MContainer>
                    </template>
                </transition>
            </MContainer>
        </template>
    </MBottomSheet>
</template>

<style scoped>
    .list-item {
        background-color: white;

        transition: all 200ms ease-in-out;

        &:active {
            transition: all 0ms;
            background-color: rgb(95%, 95%, 95%);
        }
    }
</style>

<style>
    /* forward */
    .route-forward-enter-from {
        transform: translate(100%, 0%);
        opacity: 0%;
    }
    .route-forward-enter-active {
        transition: all 300ms ease-in-out;
    }
    .route-forward-enter-to {
        transform: translate(0%, 0%);
    }
    .route-forward-leave-from {
        transform: translate(0%, 0%);
    }
    .route-forward-leave-active {
        transition: all 300ms ease-in-out;
    }
    .route-forward-leave-to {
        transform: translate(-100%, 0%);
        opacity: 0%;
    }

    /* back */
    .route-back-enter-from {
        transform: translate(-100%, 0%);
        opacity: 0%;
    }
    .route-back-enter-active {
        transition: all 300ms ease-in-out;
    }
    .route-back-enter-to {
        transform: translate(0%, 0%);
    }
    .route-back-leave-from {
        transform: translate(0%, 0%);
    }
    .route-back-leave-active {
        transition: all 300ms ease-in-out;
    }
    .route-back-leave-to {
        transform: translate(100%, 0%);
        opacity: 0%;
    }
</style>
